import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";

import ReactGA from 'react-ga';

import Header from './components/Header';

import './App.css';
import logo from './components/logo.png';
import philosophy from './components/philosophy.png';

import consultationdgrm from './components/consultation-dgrm.jpg';

import treatmentone from './components/treatments-one.jpg';
import treatmenttwo from './components/treatments-two.jpg';
import lotos from './components/lotos2.png';
import biglotos from './components/lotos.png';
import contactus from './components/contactus.jpg';
import consultation from './components/consultation.jpg';
import treatment from './components/treatments.jpg';
import aboutus from './components/aboutus.jpg';

//import mesotherapy from './components/mesotherapy.jpeg';
//import alopecia from './components/alopecia.jpeg';


//import biorevitalisation1 from './components/bio1.png';
//import biorevitalisation2 from './components/biorevitalisation2.png';

//import vampire1 from './components/vam1.png';
//import vampire2 from './components/vamp1.jpg';

//import radio1 from './components/radio1.png';
//import radio2 from './components/radio2.jpg';

//import carboxy from './components/carboxy.jpg';

//import cliniccare1 from './components/cliniccare1.png';
//import cliniccare2 from './components/cliniccare2.png';
 
//import alopecia from './components/alopecia3.jpg';
//import mesotherapy from './components/mesotherapy3.jpg';
//import biorevitalisation from './components/biorevitalisation3.jpg';
//import facelift from './components/facelift.jpg';
//import radiofrequency from './components/radiofrequency.jpg';


import alopecia from './components/alopecia4.jpg';
import mesotherapy from './components/mesotherapy5.jpg';
import biorevitalisation from './components/biorevitalisation5.jpg';
import radiofrequency from './components/radiofrequency5.jpg';
import carboxy from './components/carboxy5.jpg';
import facelift from './components/facelift4.jpg';
import cliniccare from './components/cliniccare6.png';

/*

import cliniccare1 from './components/cliniccare1.png';
import cliniccare2 from './components/cliniccare2.png';

*/

class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize('UA-147360698-1');
  }
  
  render() {
    return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Route exact path="/" component={Home} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/consultation" component={Consultation} />
        <Route path="/treatments" component={Treatments} />
        <Route path="/contactus" component={ContactUs} />
      </BrowserRouter>
     </div>
  );
  }
}
export default App;

//      <!--div>You are unique in every way and we focus on preserving your natural beauty</div-->

function Home() {
  ReactGA.pageview("/");
 
  return (
    <>
      <div className="content-home">
        <img className="home-logo" src={logo} alt="UniqueYou" />
        <img className="home-philosophy" src={philosophy} alt="UniqueYou philosphy" />
        <div className="home-text">Our clinic provides skin care advice and medical grade aesthetic treatments using products selected from leading industry companies, based on solid research and evidence based background.</div>
      </div>
      <div className="footer">
        &copy; UniqueYou, 2019-{new Date().getFullYear()}.
      </div>
    </>
  )
}
const phoneNumber = "07979472484";

function AboutUs() {
  ReactGA.pageview("/aboutus");
 
  return (
    <>
    <div className="content-4"> 
      <div className="content-header-3">
        <img className="content-header-img-3" src={aboutus} alt={"Contact Us on " + phoneNumber} />
      </div>
      <div className="strapline">
        The consultation is free, relaxed, non-pressure and no obligation for treatment. Call for a friendly professional consultation on {phoneNumber}.
      </div>

      <div className="content-text">
        <div className="content-section-title">
          <img className="icon-lotos lotos-pink" src={lotos} alt="lotos" />
          <div className="red title-bottom"> About us </div>
        </div>
     
        <div className="text-two-column">
          <div className="column-triple">
      
          <p>
          Our clinic provides skin health and medical grade aesthetic treatments 
          using products selected from leading industry companies, based on solid research 
          and evidence based background.
          </p>
          <p><span className="name">Florica Karpovych</span> is a NMC Registered General
          Nurse and a graduate from the University of Agronomy and Bio-science in Bucharest.
          </p>
          <p>
            Florica has 20 years experience in nursing, working in private cosmetics
            surgery hospitals and NHS specialist roles. Taking her nursing career to 
            the next level, she took specialist training in Harley Street and became 
            an Aesthetic Nurse Practioner continuing to update her knowldege and practice
            with the latest techniques and products from leading companies.
          </p>
          <p>
            <span className="name">Brigid Hickey</span> is a NMC Registered General Nurse and
            an Advanced Nurse Practioner working in aesthetic and cosmetic surgery since 1992,
            she has wide experience in all aspects of the field.
          </p>
          <p>
            Brigid has worked in private cosmetic hospitals and in Harley Street clinics
            alongside world leading cosmetic surgeons.
          </p>
          </div>
        </div>
      </div>
    </div>
</>
  )
}

function Mesotherapy() {

  return (
<div>
<div className="content-section-title">
    <img className="icon-lotos lotos-brown" src={lotos} alt="lotos" />
    <div className="brown title-bottom">
      Mesotherapy
    </div>
  </div>

   <div className="text-two-column bottom-green">
      
      <div className="column-double-left">
      <p>
        Mesotherapy treatment consists of microneedling into the skin a
sterile cocktail of vitamins, minerals, antioxidants, plant extracts,
growth factors and hyaluronic acid.
      </p>
      <p>
        This treatment has double effect on skin:
        <ul>
          <li>
the microchannels produced by the needless causes micro-trauma to
skin which produces collagen, stimulates repair and tightens
enlarged skin pores
          </li>
          <li>
the cocktail of vitamins, antioxidants and hyaluronic acid provide
the ideal supplements for healthy skin, boosts hydration,
reinvigorates the skin, increases natural barriers against harmful

toxins and free radicals, targets specific concerns such as
hyperpigmentation, acne, dry and mature skin
          </li>
        </ul>
      </p>
      <p>
        Mesotherapy can be used on face, neck, cellulite, stretch marks, hair
loss, acne, hyperpigmentation.
    </p>
      <p>
The treatment is complemented with a cooling high concentration
Hyaluronic acid mask.
      </p>
      <p>
This is a minimally invasive treatment with usually no down-time
post procedure.          
      </p>     
      </div>
      <div className="column-single-rigth">
        <img  className="imgimg" src={mesotherapy} alt="UniqueYou" />
      </div>
    </div>
    </div>
  )
}

function Alopecia() {
  return (
    <div>
      <div className="content-section-title">
        <img className="icon-lotos lotos-green" src={lotos} alt="lotos" />
        <div className="green title-bottom">
          Alopecia (Hair loss)/Hair thinning
        </div>
      </div>
      
      <div className="text-two-column bottom-pink">
        <div className="column-double-left">
          <p>
            This treatment is performed by injecting into affected area of the
scalp (or full head) a special cocktail for hair growth containing
vitamins, minerals, microelements, biotin and hyaluronic acid which
stimulates the vascularisation and blood flow, fibroblast
proliferation, supports the growth of new hair, slows hair loss and
improves hair quality.
          </p>
          <p>
          This treatment can also be performed with PRP or alternating
treatments of PRP with the Hair Energy Cocktail.
          </p>
          <p>
          The treatment requires a course of minimum 6, weekly treatments
and the use of Energizing shampoo + Energizing conditioner + Hair
booster for the duration of treatment.          
          </p>
        </div>
        <div className="column-single-right">
          <img  className="imgimg" src={alopecia} alt="UniqueYou" />
        </div>
      </div>
    </div>
  )
}

function VampireFacelift() {
  return (
    <div>
      <div className="content-section-title">
        <img className="icon-lotos lotos-brown" src={lotos} alt="lotos" />
        <div className="brown title-bottom">
          Vampire Facelift and Vampire Facial /PRP (Platelet Rich Plasma)
        </div>
      </div>

      <div className="text-two-column bottom-pink">
        <div className="column-double-left">
          <p>
            Both procedures are non-surgical and minimally invasive treatments
using your own body`s natural healing powers which slows and
reverse the ageing process of cells by accelerating ibroblast
proliferation, stem cell proliferation and differentiation, collagenesis
and vascularisation.
          </p>
          <p>
          This revolutionary cutting-edge therapy places growth factors from
PRP in the exact location where we want the skin to repair and
rejuvenate itself by releasing the growth factors which trigger
surrounding cells to proliferate, stimulate repair, increase volume
and rejuvenate the skin through collagen production.
          </p>
          <p>
          A blood sample is taken from the patient at the beginning of
treatment (exactly as it is taken at your GP) and spun in a centrifuge
to harvest the PRP which is then injected in the areas of concern.
          </p>
          <p>
          PRP has fantastic healing benefits for injuries and is used to treat
burns, alopecia (thinning hair), skin ulcers, dark circles under the
eyes, stretch marks, skin rejuvenation in any area of the body,
wrinkles, acne, scars, sagging skin, grafts, bone fractures, tendons.
          </p>
          <p>
          Contraindications:<br/>
Thrombocytopenia, Platelets disfunction syndrome, systemic use of
steroids within 2 weeks, cancer, long term use of blood thinners,
pregnancy and breastfeeding, recent fever or illness, infection.
          </p>
        </div>
        <div className="column-single-right">
          <img  className="imgimg" src={facelift} alt="UniqueYou" />
         </div>
      </div>
    </div>
  )
}
function RadioFrequency() {
  return (
    <div>
      <div className="content-section-title">
        <div className="brown title-bottom">
              RadioFrequency (RF)
        </div>
      </div>

      <div className="text-two-column">
        <div className="column-double-left">
          <p>
            Radiofrequency is an effective treatment in lifting and tightening the drooping of the skin giving instant visible results without the use of needles.
          </p>
          <p>
            RF involves using energy waves to heat the deep layer of the skin
            known as dermis. The heat stimulates the production of collagen
            (neocollagenesis) and elastin, structuring and contouring the face to
            create firm, toned and youthful looking skin.
          </p>
          <p>
            This treatment is targeting loose skin on neck and chin, wrinkles,
            stretch marks, smoker lines, marionette lines and can be used in
            combination with other aesthetic treatments to enhance the results.
          </p>
          <p>
            Not recommended for people with Rosacea, pace maker or metal
            work into the body.
          </p>  
        </div>
        <div className="column-single-right">
          <img  className="imgimg" src={radiofrequency} alt="UniqueYou" />
        </div>
      </div>
    </div>
  )
}

function Carboxytheapy () {
  return (
    <div>
      <div className="content-section-title">
        <div className="brown title-bottom">
          Non-invasive Carboxytherapy         
        </div>
      </div>
      <div className="text-two-column">
        <div className="column-double-left">
        <p>
          CLINICCARE Non-invasive Carboxytherapy is an effective non-invasive
treatment during which carbon dioxide (CO2), is infused into the skin
to encourage an increased flow of oxygen.
</p>
<p>
CO2 is naturally occurring in our body-therefore it does not cause
any allergy or other unwanted reactions and is suitable for all skin
types.</p>
<p>
<b>The principle of carboxytherapy treatment:</b><br/>
This treatment is accomplished by using a Carboxytherapy gel
applied in an even layer on the skin and then a Carboxytherapy
Activator Face Mask is pressed firmly onto the gel creating a large
amount of carbon dioxide which is infused into the skin in a natural
way.</p>
<p>
This signals our body to secure oxygen to the tissue in the treated
region and accumulate natural growth factors by increased
vascularisation (vascular endothelial growth factors).
Within days after treatment there is an increased natural
distribution of Oxygen in treatment area, a completely new
microcirculation is created, the lymphoid drainage is maximised
giving the skin a maximum glow and healthy look plus much
improved /vanished under-eye dark circles.
</p><p>
This minimally invasive treatment offers long lasting skin
improvements by stimulating elastin production, tightening and
brightening the tissues, boosting blood circulation into the area
treated, reducing the dark under-eye circles and improving lymphoid
drainage.
</p>
<p>

For maximum beneficial results a series of 4-6 treatments is required
at an interval of 7-14 days as the effects are cumulative, a new
treatment will enhance the results of previous one.
          </p>
          </div>
          <div className="column-single-right">
          <img  className="imgimg" src={carboxy} alt="UniqueYou" />
        </div>
      </div>
    </div>
  )
}

function Cliniccare() {
  return (
    <div>
      <div className="content-section-title">
        <div className="brown title-bottom">
          Professional Cliniccare Facials
        </div>
      </div>
      <div className="text-three-column">
        <div className="column-single-left with-space-right">
          <p>
            Expert selection of professional facial treatments tailored to individual`s needs and addressing all skin concerns.
          </p>
          <p>
            Facial treatments are available on request or it can be added to any
  aesthetic procedure.
          </p>
        </div>
        <div className="column-double">
          <img  className="imgimg" src={cliniccare} alt="UniqueYou" />
        </div>
      </div>
    </div>
  )
}
function Biorevitalisation() {
  return (
    <div>
      <div className="content-section-title">
        <img className="icon-lotos lotos-pink" src={lotos} alt="lotos" />
        <div className="red title-bottom">
          Biorevitalisation
        </div>
      </div>

      <div className="text-two-column bottom-brown">
        <div className="column-double-left">
          <p>
            Minimally invasive procedure involving manual microinjections into
            the skin to deliver nutrients, antioxidants, vitamins and high
            concentration of non-crosslinked hyaluronic acid.
          </p><p>
            Biorevitalisation is a powerful, nourishing beauty booster providing
            an immediate skin plumping and radiance due to high concentration
            of Hyaluronic Acid; the full effect of treatment takes place in the
            weeks and months to come providing dermal regeneration,
            hydration, strengthening of the extracellular matrix and a collagen
            boosting effect. The skin looks and feels hydrated, plump, radiant
            and smooth.
            </p><p>
            It is recommended for full face including under eyes dark circles, lip
            lines, superficial wrinkles, ageing decolletage, dull/dry skin,
            blemishes, sun damaged skin, hands and hyperpigmentation.
            </p><p>
              For maximum benefits, a course of 2-4 treatments at 3-4 weeks
            interval is recommended.
            </p><p>
            Please note that this is not a Hyaluronic acid filler.
          </p>
        </div>   
       
        <div className="column-single-right">
          <img  className="imgimg" src={biorevitalisation} alt="UniqueYou" />
        </div>
      </div>
    </div>
  )
}

function Peeling () {
  return (
<div className="text-three-column">
          <div className="column-single">
            <div className="content-section-title-2">
              <img className="icon-lotos lotos-brown" src={lotos} alt="lotos" />
              <div className="brown title-bottom">
              Glycolic Acid Peel
              </div>
            </div>
            <p>
            Glycolic peel is a medical grade peel which comes in a variety of strengths and is
            performed only by medically trained staff. It works by penetrating deep into the layers 
            of the skin, dissolving excess sebum and stimulating collagen production. The glycolic
            peel in conjuction with skincare products recommendeded by your specialist can be used
            to address pigmentation irregularities, acne and photo aged skin helping to achieve a smoother, 
            healthier and younger looking skin with enhanced clarity. After the peel skin looks 
            radiant, refreshed, refined and bright.
          </p>
          <p>
            Glycolic peel is recommendeded for:
            <ul>
              <li>Fine lines and wrinkles</li>
              <li>Hyperpigmentation</li>
              <li>Acne scars</li>
              <li>Enlarged pores</li>
              <li>Uneven or rough texture</li>
              <li>Dull skin tone</li>
              <li>Acne prone skin</li>
            </ul>
          </p>
          <p>Chemical peels are commonly used on the face, but can also be used on chest, back,
        shoulders, decolletage, neck and hands.
          </p>
       
         
 
          </div>
          <div className="column-single with-space">
            <div className="content-section-title-2">
            <img className="icon-lotos lotos-brown" src={lotos} alt="lotos" />
              <div className="brown title-bottom">
                Mandelic Acid Peeling
              </div>
            </div>
            <p>
            Mandelic acid is an alpha hydroxy acid (AHA) derived from bitter almonds and has a 
            larger molecular size that penetrates the skin superficially making it less irritating
            and more effective when treating damaged or sensitive skin.
          </p>
          <p>
            This is a great option for clients who have experienced irritation with other acid treatments, 
            as it is gentler and results in fewer side effects than other peeling treatments.
            Mandelic acid has antibacterial properties and works as an exfoliant. It can be an 
            excellent chemical peel to treat hyperpigmentation, fine lines, and oily, acne-prone skin.
          </p>
          <p>
            It has anti-inflammatory properties; the treatment is safe for almost any skin type and condition, 
            including rosacea and more sensitive or redness-prone skin. Mandelic peels can be very
            effective in exfoliating dead, dull skin cells, which gives the skin a glowing complexion
            and reduces the appearance of fine lines and wrinkles.
          </p>
          <p>
            Mandelic acid helps strengthen collagen, delaying and even erasing the appearance
            of fine lines and wrinkles.
          </p>
  
          </div>
          <div className="column-single with-space">
            <div className="content-section-title-2">
            <img className="icon-lotos lotos-brown" src={lotos} alt="lotos" />
              <div className="brown title-bottom">
              Citric Acid Peeling
              </div>
            </div>
            <p>
            Citric acid is a wonderful, naturally occuring product for treating several skin problems
            like mild acne, pigmentation, clogged pores, sun tanning, wrinkles, and dark spots.
          </p>
          <p>
            The acid gently exfoliates the skin by removing dead skin cells from the top layer of the skin,
            thus encouraging new cell growth. Since it is a naturally occuring substance, it rarely causes
            an allergic reaction and is suitable for most skin types.
          </p>
          <p>
            This peeling can be used on its own or as a "booster peel" for glycolic acid peel.
          </p>
          

        
        
          </div>
       


        </div>
  )
}

function TE() {
  return (
    <div>
      <div className="content-section-title">
        <div className="brown title-bottom">
        Transdermal Electroporation (TE)
        </div>
      </div>
      <div className="text-one-column bottom-brown"> 
      <p>
            Electroporation, also known as “non-injectable mesotherapy”
involves exposing the skin to a light electrical field, which reduces the
cell wall`s resistance, making it more permeable and allowing the
transfer of serums, vitamin cocktails and nurturing solutions into the
deep layers of skin.</p>
<p>
TE can be used on its own or as part of combined treatments for
pigmentation, acne, psoriasis, cellulite, skin rejuvenation, wrinkles,
anti-ageing, rosacea, stretch marks, sun spots.
          </p>
      </div>
    </div>
  )
}
function Needleless() {
  return (
    <div>
      <div className="content-section-title">
        <img className="icon-lotos lotos-pink" src={lotos} alt="lotos" />
        <div className="red title-bottom">
            Needleless Collagen Induction treatments
        </div>
      </div>
    
      <RadioFrequency />
      <Carboxytheapy />
      <Cliniccare />
      <TE />
      
    </div>
  )
}
function Treatments() {
  ReactGA.pageview("/treatments");
 
  return (
    <>
    <div className="content-4">
      <div className="content-header-3">
        <img className="imgimg" src={treatment} alt={"Contact Us on " + phoneNumber} />
      </div>
      <div className="strapline pink">
          The consultation is free, relaxed, non-pressure and no obligation for treatment. Call for a friendly professional consultation on {phoneNumber}.
      </div>
      <div className="content-text">
        <div className="content-section-title">
          <img className="icon-lotos lotos-green" src={lotos} alt="lotos" />
          <div className="green title-bottom"> Anti-wrinkle Injections </div>
        </div>
        <div className="text-two-column bottom-pink">

         <div className="column-single">
            <p>
              Botulinum toxin type A became the most popular non-surgical anti-ageing
              treatment of choice for millions of people in the last decade. The
              treatment helps reduce the appearance of moderate to deep lines and wrinkles by temporary relaxing the muscles treated.
            </p>
            <p>
            Indications:
            <ul>
              <li>Horizontal forehead lines and wrinkles.</li>
              <li>Glabellar frown lines and wrinkle.</li>
              <li>Crow's feet lines.</li>
              <li>Repeated treatments are necessary to maintain results.</li>
            </ul> 
            </p>
          </div>
          <div classname="column-double">
            <img  className="imgimg" src={treatmentone} alt="UniqueYou" />
          </div>
        </div>
        <div className="content-section-title">
          <img className="icon-lotos lotos-pink" src={lotos} alt="lotos" />
          <div className="red title-bottom">
            Hyaluronic Acid Dermal Fillers
          </div>
        </div>
        <div className="text-two-column bottom-brown">
         <div className="column-single">
         <p>
          Hyaluronic acid fillers are used to contour, volumise, sculpt, restore and
           enhance natural facial beauty resulting in youthful, balanced appearance.</p>
          <p>
          Hyaluronic acid fillers are recommended for:
          <ul>
            <li>Lips contouring.</li>
            <li>Lips enhancement.</li>
            <li>Treatment of "smoker" lines.</li>
            <li>Treatment of "marionette" lines.</li>
            <li>Cheek augmentation.</li>
          </ul> 
          </p>
      
         </div>
         <div classname="column-double">
            <img  className="imgimg" src={treatmenttwo} alt="UniqueYou" />
          </div>      
        </div>
       

        <Mesotherapy />
        <Alopecia />
        <Biorevitalisation />
        <VampireFacelift />
        <Needleless />
    
        

    
        <Peeling />
      </div>
    </div>
</>
  )
}

function Consultation() {
  ReactGA.pageview("/consultation");
 
  return (
    <>
    <div className="content-4">
      <div className="content-header-3">
        <img className="content-header-img-3" src={consultation} alt={"Contact Us on " + phoneNumber} />
      </div>
      <div className="strapline pink">
         Call for a friendly professional consultation on {phoneNumber}.
      </div>
      <div className="content-text">
        <div className="content-section-title">
        <img className="icon-lotos lotos-pink" src={lotos} alt="lotos" />
          <div className="red title-bottom">
            Consultation
          </div>
        </div>
        <div className="text-two-column">
          <div className="column-single">
            <p>
              Consultations are free, relaxed and non-pressure for treatment.
            </p>
            <p>
              All treatments are planned and agreed after a face-to-face consultation.
            </p>
            <p>
              During the consultation you will tell us about your skin concerns and we
              will assess your skin condition and offer you an honest, professional 
              advice on treatment.
            </p>
            <p>
              We will provide all relevant information about the products to be used for 
              your tailored treatment, giving you time to make an informed decision. 
            </p>
            <p className="green">
              Get your personalised skin consultation from an expert !
            </p>
          </div>
          <div className="column-double">
            <img className="imgimg" src={consultationdgrm} alt="lotos" />
          </div>
        </div>
      </div>
    </div>
            </>

  )
}

function ContactUs() {
  ReactGA.pageview("/contactus");
 
  return (
    <>
    <div className="content-3">
      <div className="content-header-3">
        <img className="content-header-img-3" src={contactus} alt={"Contact Us on " + phoneNumber} />
      </div>
      <div className="strapline pink">
          All treatments are planned and agreed after a face-to-face consultation. Call for a friendly consultation on {phoneNumber}.
      </div>
      <div className="content-text">
        <div className="content-section-title">
          <img className="icon-lotos lotos-pink" src={lotos} alt="lotos" />
          <div className="red title-bottom">
            Contact Us
          </div>
        </div>
        <div className="text-two-column">
          <div className="column-single">
            <p>
              Unique You Ltd <br/>
              90 Teversham Drift<br/>
              Cambridge <br/>
              CB1 3LL <br/>
              United Kingdom
            </p>

            <p>
              Call us on {phoneNumber} <br/>
              E-mail: info@unique-you.co.uk
            </p>

          </div>
          <div className="column-double column-right">
            <img className="contactus-lotos lotos-pink" src={biglotos} alt="lotos" />
          </div>
        </div>
      </div>
    </div>
            </>

  )
}
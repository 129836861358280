import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import logo from './logo.png';
import './Header.css';

const phoneNumber = "07979472484";


class Header extends Component {
    
    render() {
        return (
          <div>
            <Navbar bg="white" expand="lg" id="navbar">
            <Navbar.Brand><Link to="/"><img src={logo} alt="UniqueYou logo" className="d-inline-block align-top logo"/></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav variant="pills" className="mr-auto">
                <NavLink activeStyle={{color: "#F2ABCB"}} className="ml-5" eventKey="1" to="/aboutus">about us</NavLink>
                <NavLink activeStyle={{color: "#F2ABCB"}} className="ml-5" eventKey="2" to="/consultation">consultation</NavLink>
                <NavLink activeStyle={{color: "#F2ABCB"}} className="ml-5" eventKey="3" to="/treatments">treatments</NavLink>
                <NavLink activeStyle={{color: "#F2ABCB"}} className="ml-5" eventKey="4" to="/contactus">contact us</NavLink>
              </Nav>
            </Navbar.Collapse>

          <span className="callus">free consultation {phoneNumber}</span>
          </Navbar>
        </div>
        )  
    }   
}

export default withRouter(Header);
